import { useEffect } from 'react'
import { FloatingAlerts } from 'mc-ui/collections/FloatingAlerts'
import { AlertEventTypes } from './types'
import { AlertsProvider, useAlertsContext } from 'mc-ui/context/AlertsContext'
import { Alert } from 'mc-ui/utils/hooks/useAlerts'

/*
 * WARNING: This component is intended for use only in legacy pages.
 * It should not be used in new code.
 */
const InstUIFlashComponentInternal = () => {
  const { addAlert, resetAlerts, alerts } = useAlertsContext()

  useEffect(() => {
    const eventHandlers = {
      [AlertEventTypes.AddInstUIFlashMessage]: (event: CustomEvent<Alert>) => {
        addAlert(event.detail)
      },
      [AlertEventTypes.ResetInstUIFlashMessages]: resetAlerts,
    }

    Object.entries(eventHandlers).forEach(([event, handler]) => {
      window.addEventListener(event, handler)
    })

    return () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        window.removeEventListener(event, handler)
      })
    }
  }, [addAlert, resetAlerts])

  return <FloatingAlerts alerts={alerts} />
}

const InstUIFlashComponent = () => {
  return (
    <AlertsProvider>
      <InstUIFlashComponentInternal />
    </AlertsProvider>
  )
}

export { InstUIFlashComponent }
