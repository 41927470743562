// Attach the current version of jquery to the window as `f$` for
// historical reasons. This replaces the attachment that was
// previously done in
// `vendor/assets/javascripts/jquery-3.1.1/load.js`.
//
// Please note that if you're running in the modern js context and you
// need to use jquery, you *should not* import this setup. This is
// meant to be used *only* in contexts where we need to support the
// legacy sprockets codebase, which expects `window.f$` to always be
// ready. In new code, consider just importing jquery directly.

import $ from 'jquery';
import 'jquery-ui';

// This has to be done at the same time as the imports themselves:
window.f$ = $.noConflict(true);
