import ready from 'document-ready-promise'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { InstUIFlashComponent } from '../mc-app/common/InstUIFlash/InstUIFlashComponent'

const instUIFlashSetup = () => {
  ready().then(() => {
    const node = document.createElement('div')
    node.id = 'instui_flash_container'

    document.body.appendChild(node)

    createRoot(node).render(<InstUIFlashComponent />)
  })
}

export default instUIFlashSetup
